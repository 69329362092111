
.product-action-dark button.btn-product.btn-cart:hover{
	color: rgb(180, 174, 174);
}

.product-action-dark  button.btn-product.btn-quickview:hover{
	color: rgb(180, 174, 174);
}

.product-action-dark button.btn-product.btn-cart:hover span{
	color: rgb(180, 174, 174);
}

.product-action-dark button.btn-product.btn-quickview:hover span{
	color: rgb(180, 174, 174);
}

.btn-qty-custom-right{
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border:1px solid #ccc;
	border-radius: 0 4px 4px 0;
	cursor: pointer;
}

.btn-qty-custom-right:hover{
	border:1px solid #f2f2f2;

}

.btn-qty-custom-left{
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border:1px solid #ccc;
	border-radius: 4px 0 0 4px;
	cursor: pointer;
	user-select: none;

}

.btn-qty-custom-left:hover{
	border:1px solid #f2f2f2;
	user-select: none;

}


.input-qty-custom{
	height: 40px;
	width: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-top:1px solid #ccc;
	border-bottom:1px solid #ccc;
	color: #19386e;
	font-weight: bold;
	user-select: none;

}